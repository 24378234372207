export const environment = {
    production: false,
    firebase: {
      apiKey: "AIzaSyCGkXIVloYKmc10fb1GLUUyhIlEoEhJsUo",
  authDomain: "inversionagave.firebaseapp.com",
  projectId: "inversionagave",
  storageBucket: "inversionagave.appspot.com",
  messagingSenderId: "955280543335",
  appId: "1:955280543335:web:603ec5f6ba7ee4b10ccec2",
  measurementId: "G-F3HN4V9Q7X"
    },
    apiKeyGoogleCloud: "AIzaSyDJtupjm2crZFXE2XRE23TyrlbI570GjEk",
    stripe:"pk_live_51IdgmLDP1g0gNw9guLg4w8dahp3wAp1qw530Kl5csQBP9rsCEBmgcqOq044x0e3NJRQvt6bmOMFAgvcbt0cPKbjp00yPgaTXXe",
  };