import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-masthead',
  templateUrl: './masthead.component.html',
  styleUrls: ['./masthead.component.css',
              '../../../assets/template/css/styles.css'],
})
export class MastheadComponent implements OnInit {
  @Output() showHome = new EventEmitter<boolean>();

  constructor( private router: Router) {}

  ngOnInit(): void {}

  

  
}
